<template>
  <transition name="slide-settings">
    <div v-show="show">
      <small>
        {{ translations.settingsViewLead }}
      </small>
      <div class="webc-cookie-check-all">
        <VSwitcher
            v-model="allChecked"
            :label="translations.switchAll"
            id="all"
            @input="checkAll"
        />
      </div>
      <Category
          v-for="category in categories"
          :key="category"
          :category="category"
          :checkCategories="checkCategories"
          @on-check="updateCheckedCategories"
          :disabled="category === 'required'"
          :items="items[category].replaceAll(' ', '').split(',')"
      />

      <div class="webc-cookie-save">
        <VButton @click="saveSettings">
          {{ translations.saveSettings }}
        </VButton>
      </div>
    </div>
  </transition>
</template>

<script>
import VButton from '../general/VButton';
import VSwitcher from '../general/VSwitcher'
import Category from './CookieCategory'

export default {
  components: { VSwitcher, Category, VButton },
  inject: ['translations'],
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      allChecked: false,
      checkCategories: ['required'],
      items: {
        required:
            'service_popup,acceptedCookies,acceptedCookiesRemarketing,browserId,CINKCIARZ_FX,ck_NotLoggedSessionTime,ck_SessionTime,collect_bank,collect_country,collect_currency,csrfToken,display,Exchange,language,missing_required_fields_form,pay_link_last_pos,user,__cf_bm,_cfuvid,_grecaptcha,grecaptcha,rca,rcb,rcc,OCSESSID,ls_sid,tid,fileDownloadToken',
        settings: 'app,calculatorCurrencies,calc_pl,calc_en,chartCached,converter,converter_badge,converter_seen,currency,dashboard_currency,forex_invest_banner,link_banner,remit_calculator,remits_payment_method,social_orders_base_currency,social_orders_quote_currency',
        stats:
            '_ga,_ga_,_gat,_gid,collect,r/collect,__ls_exp,__ls_off,__ls_sid,__ls_uid,__lsw_session,_pk_id,_pk_ses,piwik.php',
        marketing: '_gac_,_gcl_au,ads/ga-audiences,ANID,IDE,NID,test_cookie',
        multimedia: '_Secure_ENID,_Secure_YEC,AEC,DSID,HSID,PREF,SID,VISITOR_INFO1_LIVE,YSC,other'
      },
      show: false
    }
  },
  methods: {
    updateCheckedCategories(value, category) {
      if (this.checkCategories.includes(value) && value) {
        return
      }
      if (value) {
        this.checkCategories.push(category)
      } else {
        this.checkCategories = this.checkCategories.filter(
            (el) => el !== category
        )
      }

      this.allChecked = this.checkCategories.length === this.categories.length
    },
    checkAll(checked) {
      this.checkCategories = checked ? [...this.categories] : ['required']
    },
    saveSettings() {
      const categories =
          this.categories.length === this.checkCategories.length
              ? ['all']
              : this.checkCategories

      this.$emit('save-settings', categories.join(','))
    }
  },
  mounted() {
    const cookie = this.$cookies.get('acceptedCookiesRemarketing')
    if (cookie) {
      this.checkCategories = cookie.split(',')
      this.allChecked = this.categories.every(el => this.checkCategories.includes(el))
    }

    setTimeout(() => (this.show = true), 1)
  }
}
</script>