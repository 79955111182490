<template>
  <div></div>
</template>
<script>
export default {
    props: {
        gtmCode: {
            type: String,
            default: ''
        }
    },
    mounted() {
      if (this.$cookies.get('acceptedCookiesRemarketing')) {
        this.initGTM()
      }
      window.addEventListener('on-cookies-save', () => {
        this.initGTM()
      })
    },
    methods: {
        setGoogleConsent(checkedCategories) {
            checkedCategories = checkedCategories || ''
            const getCategoryConsent = (consentCategory) => checkedCategories.includes(consentCategory) ? 'granted': 'denied'

            this.gtag('consent', 'default', {
                'ad_storage': getCategoryConsent('marketing'),
                'analytics_storage': getCategoryConsent('stats'),
                'personalization_storage': getCategoryConsent('marketing'),
                'functionality_storage': getCategoryConsent('settings'),
                'security_storage': getCategoryConsent('required'),
                'ad_user_data': getCategoryConsent('marketing'),
                'ad_personalization': getCategoryConsent('marketing')
            })
        },
        gtag() {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push(arguments);
        },
        loadGTMScript() {
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', this.gtmCode);
        },
      initGTM() {
        this.setGoogleConsent(this.$cookies.get('acceptedCookiesRemarketing'))
        if (window.dataLayer && window.dataLayer.length) {
          this.loadGTMScript()
        }
      }
    }
}
</script>