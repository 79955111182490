<template>
  <div class="webcc-cookie-category">
    <div>
      <div class="webcc-cookie-category-heading">
        <h5>
          {{ translations[`${category}Heading`]}}
        </h5>
        <VSwitcher
            v-model="checked"
            :id="category"
            :disabled="disabled"
            @input="$emit('on-check', checked, category)"
        />
      </div>
      <small>
        {{ translations[`${category}Lead`]}}
        <VButton link inline @click="showDetails = !showDetails">
          {{
            showDetails ? translations.hideDetails : translations.showDetails
          }}
        </VButton>
      </small>
    </div>
    <VCollapse ref="collapse">
      <CookieItem
          v-for="item in items"
          :key="item"
          :translations="translations[category][item]"
          :duration-text="translations.duration"
      />
      <small>
        <VButton @click="showDetails = false" link inline>
          {{ translations.hideDetails }}
        </VButton>
      </small>
    </VCollapse>
  </div>
</template>

<script>
import VButton from '../general/VButton'
import VCollapse from '../general/VCollapse'
import VSwitcher from '../general/VSwitcher'
import CookieItem from './CookieItem'

export default {
  components: { VCollapse, VSwitcher, VButton, CookieItem },
  inject: ['translations'],
  props: {
    category: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checkCategories: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showDetails: false,
      checked: this.disabled
    }
  },
  watch: {
    checkCategories() {
      this.checked = this.disabled
          ? this.checked
          : this.checkCategories.includes(this.category)
    },
    showDetails() {
      this.$refs.collapse.toggle()
    }
  }
}
</script>