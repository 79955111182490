<template>
  <div class="webc-cookie-item">
    <div class="webc-cookie-item-info">
      <span>
        {{ translations.name}}
      </span>
      <small class="webc-cookie-item-duration">
        {{ durationText }}
        {{ translations.duration}}
      </small>
    </div>
    <small>
      {{ translations.desc}}
      <a v-if="translations.url" target="_blank" :href="translations.url">{{translations.urlText}}</a>
    </small>
  </div>
</template>
<script>
export default {
  props: {
    translations: {
      type: Object,
      required: true
    },
    durationText: {
      type: String,
      required: true
    }
  }
}
</script>