<template>
  <transition name="fade" @after-enter="showContent = true">
    <div :class="`webc-modal webc-modal-${size}`" v-if="showModal" @click="onClickOutside">
      <transition name="slide" @after-leave="showModal = false">
        <div class="webc-modal-dialog" v-if="showContent">
          <div class="webc-modal-content" @click.stop>
            <slot />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    clickOutside: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      required: ''
    }
  },
  data() {
    return {
      showModal: false,
      showContent: false
    }
  },
  mounted() {
    this.setModalContentHeight()
    window.addEventListener('resize', () => {
      this.setModalContentHeight()
    })
  },
  methods: {
    toggle() {
      if (this.showModal) {
        this.close()
      } else {
        this.open()
      }
    },
    open() {
      this.showModal = true
    },
    close() {
      this.showContent = false
    },
    onClickOutside() {
      this.showModal = this.clickOutside ? false : this.showModal
    },
    setModalContentHeight() {
      const root = document.documentElement
      root.style.setProperty('--modal-content-height', `${window.innerHeight * 0.95}px`)
      root.style.setProperty('--modal-height', `${window.innerHeight}px`)
    }
  },
  watch: {
    showModal(value) {
      document.body.style.overflow = value ? 'hidden' : '';
    }
  }
}
</script>