<template>
  <div>
    <VModal
        v-if="!$cookies.get('acceptedCookiesRemarketing') || settingsTriggers.length"
        ref="modal"
        class="webc-modal-cookies"
        :size="activeView === 'settings' ? 'lg' : ''"
        :click-outside="false"
    >
      <h2 class="webc-modal-cookies-heading">
        {{ translations.heading }}
      </h2>
      <component
          :is="activeView"
          :categories="categories"
          @change-view="changeView"
          @save-settings="saveSettings"
      />
    </VModal>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCookies from 'vue-cookies'

import VModal from '../general/VModal'
import Intro from '../ModalCookies/CookieIntro'
import Settings from '../ModalCookies/CookieSettings'

import content from '../ModalCookies/translations'

Vue.use(VueCookies)

export default {
  components: { VModal, Intro, Settings },
  props: {
    lang: {
      type: String,
      default: 'pl'
    },
    unsecure: {
      type: Boolean,
      default: false
    },
    onSaveFnName: {
      type: String,
      default: null
    },
    settingsTriggersSelector: {
      type: String,
      default: '.cookies-modal-trigger'
    },
    gtmCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeView: 'intro',
      categories: ['required', 'settings', 'stats', 'marketing', 'multimedia'],
      domain: '',
      translations: content[this.lang],
      componentName: 'ckpl-webc',
      settingsTriggers: [],
    }
  },
  mounted() {

    if(process.env.NODE_ENV !== 'development') {
        this.insertCss()
    }

    if (!this.$cookies.get('acceptedCookiesRemarketing')) {
      this.$refs.modal.open()
    }

    this.getDomain()

    this.settingsTriggers = document.querySelectorAll(this.settingsTriggersSelector)
    this.settingsTriggers.forEach(trigger => {
      trigger.addEventListener('click', () => {
        this.activeView = 'settings'
        this.$refs.modal.open()
      })
    })
  },
  methods: {
    changeView(view) {
      this.activeView = view
    },
    saveSettings(value) {
      const categories =
          value === 'all' ? this.categories.join(',') : value

      this.$cookies.set('acceptedCookiesRemarketing', categories, 365 * 24 * 60 * 60, '/', this.domain, !this.unsecure)
      this.$emit('on-cookies-save')

      if(this.onSaveFnName && window[this.onSaveFnName]) {
        window[this.onSaveFnName]()
      }
        const event = new Event('on-cookies-save')
        dispatchEvent(event)

      this.$refs.modal.close()
    },
    getDomain() {
      const { hostname } = window.location
      this.domain =
          hostname.indexOf('.') !== hostname.lastIndexOf('.')
              ? hostname.slice(-1 * (hostname.length - hostname.indexOf('.')))
              : '.' + hostname
    },
    insertCss() {
      const { src } = document.querySelector(`script[src*=${this.componentName}]`) || {}

      if (!src) {
        return
      }

      const cookieEl = document.querySelector(`${this.componentName}-modal-cookies`)
      const link = document.createElement('link')
      link.href = src.replace('.js', '.css')
      link.rel = 'stylesheet'
      cookieEl?.shadowRoot.appendChild(link)

    },
  },
  provide() {
    return {
      translations: this.translations
    }
  }
}
</script>