export default {
  pl: {
    heading: 'Szanujemy Twoją prywatność',
    introLead:
        'Gdy przeglądasz naszą stronę, chcielibyśmy wykorzystywać pliki cookies i inne, zbliżone technologie do zbierania danych (m.in. adresy IP lub inne identyfikatory internetowe) w trzech głównych celach: analizy statystyki ruchu na stronie, kierowania do Ciebie reklam w innych miejscach w internecie, używania wtyczek społecznościowych. Kliknij poniżej, by wyrazić zgodę lub przejdź do ustawień, by dokonać szczegółowych wyborów używanych plików cookies.',
    goToSettings: 'Przejdź do ustawień',
    acceptRequired: 'Akceptuję wymagane cookies',
    acceptAll: 'Akceptuję wszystkie cookies',
    settingsViewLead:
        'Oto używane w naszym serwisie usługi, które mogą zapisywać na Twoim urządzeniu pliki cookies. Wybierz odpowiadające Ci ustawienia. Możesz do nich zawsze wrócić, używając odnośnika zamieszczonego w polityce cookies.',
    saveSettings: 'Zapisz ustawienia',
    switchAll: 'Wszystkie:',
    showDetails: 'pokaż szczegóły',
    hideDetails: 'ukryj szczegóły',
    duration: 'Ważne:',
    requiredHeading: 'Niezbędne',
    requiredLead:
        'Te pliki cookies są konieczne do poprawnego funkcjonowania strony i jej komponentów.',
    required: {
      service_popup: {
        name: '#-service-popup',
        desc: 'Cinkciarz.pl: Zapamiętuje wybranie "Nie pokazuj tego komunikatu ponownie" podczas zmiany dostawcy.',
        duration: 'Nie wygasa'
      },
      acceptedCookies: {
        name: 'acceptedCookies',
        desc: 'Cinkciarz.pl: Zapamiętuje wybory odnośnie cookies i podobnych technologii.',
        duration: '2 lata'
      },
      acceptedCookiesRemarketing: {
        name: 'acceptedCookiesRemarketing',
        desc: 'Cinkciarz.pl: Zapamiętuje wybory odnośnie cookies i podobnych technologii.',
        duration: '2 lata'
      },
      browserId: {
        name: 'browserId',
        desc: 'Cinkciarz.pl: Wymagany do poprawnego działania zaufanych przeglądarek.',
        duration: 'Nie wygasa'
      },
      CINKCIARZ_FX: {
        name: 'CINKCIARZ_FX',
        desc: 'Cinkciarz.pl: Podtrzymuje sesję użytkownika.',
        duration: '1 sesja'
      },
      ck_NotLoggedSessionTime: {
        name: 'ck.NotLoggedSessionTime (localStorage)',
        desc: 'Cinkciarz.pl: Przechowuje pozostały czas sesji - dla niezalogowanego usera',
        duration: 'Nie wygasa'
      },
      ck_SessionTime: {
        name: 'ck.SessionTime (localStorage)',
        desc: 'Cinkciarz.pl: Przechowuje pozostały czas sesji',
        duration: 'Nie wygasa'
      },
      collect_bank: {
        name: 'collect-bank-#',
        desc: 'Cinkciarz.pl: Zapamiętuje ostatnio wybrany bank w usłudze Collect.',
        duration: 'Nie wygasa'
      },
      collect_country: {
        name: 'collect-country-#',
        desc: 'Cinkciarz.pl: Zapamiętuje ostatnio wybrany kraj w usłudze Collect.',
        duration: 'Nie wygasa'
      },
      collect_currency: {
        name: 'collect-currency-#',
        desc: 'Cinkciarz.pl: Zapamiętuje ostatnio wybraną walutę w usłudze Collect.',
        duration: 'Nie wygasa'
      },
      csrfToken: {
        name: 'csrfToken',
        desc: 'Cinkciarz.pl: Zabezpiecza przed atakami csrf.',
        duration: 'Nie wygasa'
      },
      display: {
        name: 'display',
        desc: 'Cinkciarz.pl: Zachowuje Twój status użytkownika dla wszystkich żądań strony.',
        duration: 'Nie wygasa'
      },
      Exchange: {
        name: 'Exchange',
        desc: 'Cinkciarz.pl: Zapamiętuje dane wybrane w ostatniej transakcji (waluty i konta).',
        duration: 'Nie wygasa'
      },
      language: {
        name: 'language',
        desc: 'Cinkciarz.pl: Przechowuje informację o języku, w jakim przeglądasz naszą stronę.',
        duration: '29 dni'
      },
      missing_required_fields_form: {
        name: 'missing-required-fields-form-#',
        desc: 'Cinkciarz.pl: Zapamiętuje, że formularz wypełniania brakujących danych został już wyświetlony.',
        duration: 'Nie wygasa'
      },
      pay_link_last_pos: {
        name: 'pay-link-last-pos-#',
        desc: 'Cinkciarz.pl: Zapamiętuje ostatnio wybrany punkt płatności przy definiowaniu linka do płatności.',
        duration: 'Nie wygasa'
      },
      user: {
        name: 'user',
        desc: 'Cinkciarz.pl: Przechowuje informacje o tym, czy klient pochodzi z USA.',
        duration: 'Nie wygasa'
      },
      __cf_bm: {
        name: '__cf_bm',
        desc: 'Cloudflare: Umożliwienia usłudze dostarczanej przez Cloudflare ograniczenie zautomatyzowanego ruchu, aby chronić stronę internetową przed botami. ',
        duration: ' 1 sesja'
      },
      _cfuvid: {
        name: '_cfuvid',
        desc: 'Cloudflare: Umożliwienia usłudze dostarczanej przez Cloudflare rozróżnienie poszczególnych użytkowników mających ten sam adres IP na potrzeby bezpiecznego działania strony internetowej.',
        duration: '1 sesja'
      },
      _grecaptcha: {
        name: '_grecaptcha (local storage)',
        desc: 'Google: Wymagane do poprawnego działania reCAPTCHA.',
        duration: '6 miesięcy'
      },
      grecaptcha: {
        name: 'grecaptcha',
        desc: 'Google: Wymagane do poprawnego działania reCAPTCHA.',
        duration: 'Nie wygasa'
      },
      rca: {
        name: 'rc::a',
        desc: 'Google: Plik cookie umożliwiający poprawne rozróżnianie ruchu generowanego przez ludzi i roboty.',
        duration: 'Nie wygasa'
      },
      rcb: {
        name: 'rc::b',
        desc: 'Google: Plik cookie umożliwiający poprawne rozróżnianie ruchu generowanego przez ludzi i roboty.',
        duration: '1 sesja'
      },
      rcc: {
        name: 'rc::c',
        desc: 'Google: Plik cookie umożliwiający poprawne rozróżnianie ruchu generowanego przez ludzi i roboty.',
        duration: '1 sesja'
      },
      OCSESSID: {
        name: 'OCSESSID',
        desc: 'OpenCart: Plik cookie wymagany do poprawnego funkcjonowania systemu OpenCart. Odpowiada za podtrzymanie sesji użytkownika. Przechowuje informacje np. o tym, czy jesteś zalogowany do swojego konta i co dodałeś do koszyka.',
        duration: '1 sesja'
      },
      ls_sid: {
        name: 'ls_sid_*',
        desc: 'Landingi: Rejestruje unikalną wizytę. Służy do naliczania limitu odwiedzin strony na platformie',
        url: 'https://www.landingi.com/pl/',
        urlText: 'Landingi',
        duration: '30 minut'
      },
      tid: {
        name: 'tid',
        desc: 'Landingi: Techniczne cookies służące do prawidłowego działania strony na platformie',
        url: 'https://www.landingi.com/pl/',
        urlText: 'Landingi',
        duration: '1 miesiąc'
      },
      fileDownloadToken: {
        name: 'fileDownloadToken*',
        desc: 'Cinkciarz.pl: Umożliwienia pobierania plików (csv, pdf, doc) ze strony.',
        duration: '1 sesja'
      },
    },
    settingsHeading: 'Ustawienia',
    settingsLead:
        'Te pliki cookies przechowują Twoje indywidualne ustawienia, usprawniając działanie strony i jej funkcji.',
    settings: {
      app: {
        name: 'app-*',
        desc: 'Cinkciarz.pl: Zapis informacji o zamknięciu banera aplikacji mobilnej.',
        duration: '10 lat'
      },
      calculatorCurrencies: {
        name: 'calculatorCurrencies_{language}',
        desc: 'Cinkciarz.pl: Zapisuje wybrane ustawienia kalkulatora na Wymianie Walut.',
        duration: 'Nie wygasa'
      },
      calc_pl: {
        name: 'calculatorCurrencies_pl',
        desc: 'Cinkciarz.pl: Zapisuje Twoje wybory w kalkulatorze walutowym (wybrana waluta, kwota itp.).',
        duration: '1 rok'
      },
      calc_en: {
        name: 'calculatorCurrencies_en',
        desc: 'Cinkciarz.pl: Zapisuje Twoje wybory w kalkulatorze walutowym (wybrana waluta, kwota itp.).',
        duration: '1 rok'
      },
      chartCached: {
        name: 'chartCached',
        desc: 'Cinkciarz.pl: Zapamiętuje wybrane ustawienia wykresów.',
        duration: 'Nie wygasa'
      },
      converter: {
        name: 'converter',
        desc: 'Cinkciarz.pl: Zapisuje wybrane ustawienia kalkulatora na Wymianie Walut',
        duration: 'Nie wygasa'
      },
      converter_badge: {
        name: 'converter-badge(local storage)',
        desc: 'Cinkciarz.pl: Zapisuje wybrane ustawienia kalkulatora na Wymianie Walut.',
        duration: 'Nie wygasa'
      },
      converter_seen: {
        name: 'converter-seen(local storage)',
        desc: 'Cinkciarz.pl: Zapisuje wybrane ustawienia kalkulatora na Wymianie Walut.',
        duration: 'Nie wygasa'
      },
      currency: {
        name: 'currency',
        desc: 'Cinkciarz.pl: Przechowuje Twoje preferencje odnośnie wybieranych walut.',
        duration: 'Nie wygasa'
      },
      dashboard_currency: {
        name: 'dashboard-currency(local storage)',
        desc: 'Cinkciarz.pl: Przechowuje wybraną walutę na dashboard.',
        duration: 'Nie wygasa'
      },
      forex_invest_banner: {
        name: 'forex-invest-banner-*',
        desc: 'Cinkciarz.pl: Zapis informacji o zamknięciu banera forex.',
        duration: '10 lat'
      },
      link_banner: {
        name: 'link_banner-*',
        desc: 'Cinkciarz.pl: Zapis informacji o zamknięciu banera płatności linkiem.',
        duration: '10 lat'
      },
      remit_calculator: {
        name: 'remit_calculator_{language}',
        desc: 'Cinkciarz.pl: Zapisuje wybrane ustawienia kalkulatora na przekazach.',
        duration: 'Nie wygasa'
      },
      remits_payment_method: {
        name: 'remits-payment-method',
        desc: 'Cinkciarz.pl: Zapisuje ostatnio wybraną metodę płatności na kalkulatorze przekazów pieniężnych USA.',
        duration: '1 rok'
      },
      social_orders_base_currency: {
        name: 'social-orders-base-currency-* (local storage)',
        desc: 'Cinkciarz.pl: Przechowuje walutę wejściową w zleceniu społecznościowym.',
        duration: 'Nie wygasa'
      },
      social_orders_quote_currency: {
        name: 'social-orders-quote-currency-* (local storage)',
        desc: 'Cinkciarz.pl: Przechowuje walutę wyjściową w zleceniu społecznościowym.',
        duration: 'Nie wygasa'
      }
    },
    statsHeading: 'Statystyka',
    statsLead:
        'Dzięki tym plikom cookies możemy analizować Twoje zachowanie na stronie i na tej podstawie usprawniać jej poszczególne elementy.',
    stats: {
      _ga: {
        name: '_ga',
        desc: 'Google: Służy do rozróżniania użytkowników. Rejestruje unikalny numer użytkownika służący do zbierania statystycznych danych na temat tego, jak korzystasz z naszej strony.',
        duration: '24 miesiące'
      },
      _ga_: {
        name: '_ga_*',
        desc: 'Google: Służy do przechowywania stanu sesji. Rejestruje w jaki sposób korzystasz z naszej strony. Zawiera identyfikator kontenera w Google Analytics.',
        duration: '24 miesiące'
      },
      _gat: {
        name: '_gat',
        desc: 'Google: Ustawiony przez Google Analytics w celu kontroli liczby odpytań.',
        duration: '1 minuta'
      },
      _gid: {
        name: '_gid',
        desc: 'Google: Rejestruje unikalny numer użytkownika służący do zbierania statystycznych danych na temat tego, jak użytkownik korzysta ze strony.',
        duration: '1 dzień'
      },
      collect: {
        name: 'collect',
        desc: 'Google: Używany do przesyłania danych do Google Analytics dotyczących rodzaju urządzenia i zachowania użytkownika. Dzięki temu możemy Cię rozpoznać, nawet jeśli korzystasz z różnych urządzeń.',
        duration: '1 sesja'
      },
      'r/collect': {
        name: 'r/collect',
        desc: 'Google: Używany do przesyłania danych do Google Analytics dotyczących rodzaju urządzenia i zachowania użytkownika. Dzięki temu możemy Cię rozpoznać, nawet jeśli korzystasz z różnych urządzeń.',
        duration: '1 sesja'
      },
      __ls_exp: {
        name: '__ls_exp',
        desc: 'LiveSession: Umożliwia zbieranie statystycznych danych na temat tego, jak korzystasz z naszej strony.',
        duration: '10 lat'
      },
      __ls_off: {
        name: '__ls_off',
        desc: 'LiveSession: Umożliwia zbieranie statystycznych danych na temat tego, jak korzystasz z naszej strony.',
        duration: '10 lat'
      },
      __ls_sid: {
        name: '__ls_sid',
        desc: 'LiveSession: Umożliwia zbieranie statystycznych danych na temat tego, jak korzystasz z naszej strony.',
        duration: '10 lat'
      },
      __ls_uid: {
        name: '__ls_uid',
        desc: 'LiveSession: Umożliwia zbieranie statystycznych danych na temat tego, jak korzystasz z naszej strony.',
        duration: '10 lat'
      },
      __lsw_session: {
        name: '__lsw_session',
        desc: 'LiveSession: Umożliwia zbieranie statystycznych danych na temat tego, jak korzystasz z naszej strony.',
        duration: '1 sesja'
      },
      _pk_id: {
        name: '_pk_id#',
        desc: 'Matomo: Zbiera dane statystyczne związane z Twoimi odwiedzinami na naszej stronie: liczbę wizyt, średni czas spędzony na stronie, wyświetlone podstrony itp.',
        duration: '13 miesięcy'
      },
      _pk_ses: {
        name: '_pk_ses#',
        desc: 'Matomo: Używany przez usługę Piwik Analytics Platform do śledzenia zapytań stron użytkownika podczas sesji.',
        duration: '30 minut'
      },
      'piwik.php': {
        name: 'piwik.php',
        desc: 'Matomo: Zbiera dane statystyczne związane z Twoimi odwiedzinami na naszej stronie: liczbę wizyt, średni czas spędzony na stronie, wyświetlone podstrony itp.',
        duration: '1 sesja'
      }
    },
    marketingHeading: 'Marketing',
    marketingLead:
        'Dzięki tym plikom możemy przeprowadzać skuteczniejsze działania marketingowe, np. wyświetlając Ci reklamy bardziej dostosowane do Twoich zainteresowań.',
    marketing: {
      _gac_: {
        name: '_gac_*',
        desc: 'Google: Pochodzi z Google Analytics i służy reklamodawcom do mierzenia aktywności użytkowników oraz skuteczności kampanii reklamowych.',
        duration: '90 dni'
      },
      _gcl_au: {
        name: '_gcl_au',
        desc: 'Google: Pełni funkcję "łącznika konwersji". Pobiera informacje z kliknięć reklam i przechowuje je we własnym pliku cookie, dzięki czemu można przypisać konwersje poza stroną docelową.',
        duration: '90 dni'
      },
      'ads/ga-audiences': {
        name: 'ads/ga-audiences',
        desc: 'Google: Pixel Google tworzony do remarketingu w oparciu o zachowania użytkownika na różnych stronach internetowych.',
        duration: '1 sesja'
      },
      ANID: {
        name: 'ANID',
        desc: 'Google: Używany do wyświetlania reklam Google w witrynach innych niż Google.',
        duration: '13 miesiecy/24 miesiace (zalezne od regionu)'
      },
      IDE: {
        name: 'IDE',
        desc: 'Google: Używany przez usługę Google DoubleClick. Rejestruje Twoje zachowanie na naszej stronie po kliknięciu w reklamę. Pomaga mierzyć skuteczność naszych reklam.',
        duration: '13 miesiecy/24 miesiace (zalezne od regionu)'
      },
      NID: {
        name: 'NID',
        desc: 'Google: Używany do wyświetlania reklam Google w usługach Google niezalogowanym użytkownikom.',
        duration: '6 miesięcy'
      },
      test_cookie: {
        name: 'test_cookie',
        desc: 'Google: Sprawdza, czy Twoja przeglądarka obsługuje pliki cookies.',
        duration: '15 minut'
      }
    },
    multimediaHeading: 'Multimedia',
    multimediaLead: 'Dzięki tym plikom cookies możesz oglądać filmy z YouTube bezpośrednio na naszej stronie.',
    multimedia: {
      _Secure_ENID: {
        name: '_Secure-ENID',
        desc: 'YouTube (Google): Służy do przechowywania preferencji użytkownika i informacji o ustawieniach.',
        duration: '13 miesięcy'
      },
      _Secure_YEC: {
      name: '_Secure-YEC',
          desc: 'YouTube (Google): Służy do przechowywania preferencji użytkownika, informacji o ustawieniach oraz do wykrywania i rozwiązywania problemów z usługą YouTube. Służy również do zapewnienia bezpieczeństwa, ochrony przed spamem, oszustwami i nadużyciami.',
          duration: '13 miesięcy'
     },
      AEC: {
        name: 'AEC',
            desc: 'YouTube (Google): Służy do zapewnienia bezpieczeństwa, ochrony przed spamem, oszustwami i nadużyciami.',
            duration: '6 miesięcy'
      },
      DSID: {
        name: 'DSID',
            desc: 'YouTube (Google): Służy do identyfikowania zalogowanego użytkownika w witrynach nienależących do Google, dzięki czemu możliwe jest przestrzeganie jego ustawień personalizacji reklam.',
            duration: '2 tygodnie'
      },
      HSID: {
        name: 'HSID',
            desc: 'YouTube (Google): Pozwala Google blokować różne ataki.',
            duration: '2 lata'
      },
      PREF: {
        name: 'PREF',
            desc: 'YouTube (Google): Zapisuje informacje takie jak preferowana konfiguracja strony czy ustawienia odtwarzania.',
            duration: '8 miesięcy'
      },
      SID: {
        name: 'SID',
            desc: 'YouTube (Google): Pozwala Google blokować różne ataki.',
            duration: '2 lata'
      },
      VISITOR_INFO1_LIVE: {
        name: 'VISITOR_INFO1_LIVE',
            desc: 'YouTube (Google): Może pozwalać na wyświetlanie spersonalizowanych rekomendacji w YouTube na podstawie wcześniejszych wyświetleń i wyszukiwań. Służy również do przechowywania preferencji użytkownika, informacji o ustawieniach oraz do wykrywania i rozwiązywania problemów z usługą YouTube.',
            duration: '6 miesięcy'
      },
      YSC: {
        name: 'YSC',
            desc: 'YouTube (Google): Służy do wykrywania spamu, oszustw i nadużyć.',
            duration: '1 sesja'
      },
      other: {
        name: 'Inne cookies i podbne technologie wykorzystywane przez YouTube (Google) - np.: yt-remote-device-id, yt.innertube::nextId, CONSENT',
            desc: 'YouTube (Google) może używać również inne pliki cookies i podobne technologie zgodnie z opisem w polityce prywatności -',
            duration: '-',
            url: 'https://policies.google.com/privacy',
            urlText: 'Privacy Policy – Privacy & Terms – Google'
      },
    }
  },
  en: {
    heading: 'We respect your privacy',
    introLead:
        'When you visit our website, we would like to use cookies and other similar technologies to collect data (including IP addresses or other Internet identifiers) for three main purposes: to analyze website traffic statistics, to direct advertisements to you in other places on the Internet, to use social plugins. Click below to express your consent or go to the settings to make specific choices about the cookies used.',
    goToSettings: 'Go to settings',
    acceptRequired: 'I accept the required cookies',
    acceptAll: 'I accept all cookies',
    settingsViewLead:
        'These are the services used in our website, which can save cookies on your device. Select the appropriate settings. You can always access them using the link in the Cookies Policy.',
    saveSettings: 'Save settings',
    switchAll: 'All:',
    showDetails: 'show details',
    hideDetails: 'hide details',
    duration: 'Valid:',
    requiredHeading: 'Required',
    requiredLead:
        'These cookies are necessary for the proper functioning of the website and its components.',
    required: {
      service_popup: {
        name: '#-service-popup',
        desc: 'Cinkciarz.pl: Remembers choosing \'Do not show this message again.\' when changing providers.',
        duration: 'It does not expire'
      },
      acceptedCookies: {
        name: 'acceptedCookies',
        desc: 'Cinkciarz.pl: Remembers your choices regarding cookies and similar technologies.',
        duration: '2 years'
      },
      acceptedCookiesRemarketing: {
        name: 'acceptedCookiesRemarketing',
        desc: 'Cinkciarz.pl: Remembers your choices regarding cookies and similar technologies.',
        duration: '2 years'
      },
      browserId: {
        name: 'browserId',
        desc: 'Cinkciarz.pl: Required for trusted browsers to function properly.',
        duration: 'It does not expire'
      },
      CINKCIARZ_FX: {
        name: 'CINKCIARZ_FX',
        desc: 'Cinkciarz.pl: Maintains user sessions.',
        duration: '1 session'
      },
      ck_NotLoggedSessionTime: {
        name: 'ck.NotLoggedSessionTime (localStorage)',
        desc: 'Cinkciarz.pl: Keeps session time for not logged user',
        duration: 'It does not expire'
      },
      ck_SessionTime: {
        name: 'ck.SessionTime (localStorage)',
        desc: 'Cinkciarz.pl: Keeps session time',
        duration: 'It does not expire'
      },
      collect_bank: {
        name: 'collect-bank-#',
        desc: 'Cinkciarz.pl: Remembers the last chosen bank in the Collect service.',
        duration: 'It does not expirea'
      },
      collect_country: {
        name: 'collect-country-#',
        desc: 'Cinkciarz.pl: Remembers the last chosen country in the Collect service.',
        duration: 'It does not expire'
      },
      collect_currency: {
        name: 'collect-currency-#',
        desc: 'Cinkciarz.pl: Remembers the last chosen currency in the Collect service.',
        duration: 'It does not expire'
      },
      csrfToken: {
        name: 'csrfToken',
        desc: 'Cinkciarz.pl: Protection against csrf attacks.',
        duration: 'It does not expire'
      },
      display: {
        name: 'display',
        desc: 'Cinkciarz.pl: Preserves your user status for all page requests.',
        duration: 'It does not expire'
      },
      Exchange: {
        name: 'Exchange',
        desc: 'Cinkciarz.pl: Remembers the data selected in the last transaction (currencies and accounts).',
        duration: 'It does not expire'
      },
      language: {
        name: 'language',
        desc: 'Cinkciarz.pl: Stores information about the language in which you browse our website.',
        duration: '29 days'
      },
      missing_required_fields_form: {
        name: 'missing-required-fields-form-#',
        desc: 'Cinkciarz.pl: Records information that the missing data form has been shown to the user.',
        duration: 'It does not expire'
      },
      pay_link_last_pos: {
        name: 'pay-link-last-pos-#',
        desc: 'Cinkciarz.pl: Remembers the last chosen point of sale when defining a payment link.',
        duration: 'It does not expire'
      },
      user: {
        name: 'user',
        desc: 'Cinkciarz.pl: Stores information that indicates whether the user is from the USA.',
        duration: 'It does not expire'
      },
      __cf_bm: {
        name: '__cf_bm',
        desc: 'Cloudflare: Enable the service provided by Cloudflare to limit automated traffic to protect the website from bots.',
        duration: ' 1 session'
      },
      _cfuvid: {
        name: '_cfuvid',
        desc: 'Cloudflare: To enable the service provided by Cloudflare to distinguish between individual users sharing the same IP address for the secure operation of the website.',
        duration: '1 session'
      },
      _grecaptcha: {
        name: '_grecaptcha (local storage)',
        desc: 'Google: Required for reCAPTCHA to work properly.',
        duration: '6 months'
      },
      grecaptcha: {
        name: 'grecaptcha',
        desc: 'Google: Required for reCAPTCHA to work properly.',
        duration: 'It does not expire'
      },
      rca: {
        name: 'rc::a',
        desc: 'Google: Cookies to correctly distinguish between human and bot-generated traffic.',
        duration: 'It does not expire'
      },
      rcb: {
        name: 'rc::b',
        desc: 'Google: Cookies to correctly distinguish between human and bot-generated traffic.',
        duration: '1 session'
      },
      rcc: {
        name: 'rc::c',
        desc: 'Google: Cookies to correctly distinguish between human and bot-generated traffic.',
        duration: '1 session'
      },
      OCSESSID: {
        name: 'OCSESSID',
        desc: 'OpenCart: Necessary for proper functioning of the OpenCart system. It is responsible for maintaining the user session. It stores information such as whether you are logged in to your account and what you have added to your shopping cart.',
        duration: '1 session'
      },
      ls_sid: {
        name: 'ls_sid_*',
        desc: 'Landingi: Registers a unique visit. It is used to calculate the limit of visits to a page on the',
        url: 'https://www.landingi.com/',
        urlText: 'landing platform',
        duration: '30 minutes'
      },
      tid: {
        name: 'tid',
        desc: 'Landingi: Technical cookies for the correct operation of the website on the',
        url: 'https://www.landingi.com/',
        urlText: 'landing platform',
        duration: '1 month'
      },
      fileDownloadToken: {
        name: 'fileDownloadToken*',
        desc: 'Cinkciarz.pl: Enables to download files (csv, pdf, doc) from the site.',
        duration: '1 session'
      },
    },
    settingsHeading: 'Settings',
    settingsLead:
        'These cookies store your individual settings, improving the efficiency of the website and its functions.',
    settings: {
      app: {
        name: 'app-*',
        desc: 'Cinkciarz.pl: Record of information on the closure of the mobile app banner.',
        duration: '10 years'
      },
      calculatorCurrencies: {
        name: 'calculatorCurrencies_{language}',
        desc: 'Cinkciarz.pl: Keeps user`s settings of the currency converter on Currency Exchange service page.',
        duration: 'It does not expire'
      },
      calc_pl: {
        name: 'calculatorCurrencies_pl',
        desc: 'Cinkciarz.pl: Preserves your choices in the currency converter (selected currency, amount etc.).',
        duration: '1 year'
      },
      calc_en: {
        name: 'calculatorCurrencies_en',
        desc: 'Cinkciarz.pl: Preserves your choices in the currency converter (selected currency, amount etc.).',
        duration: '1 year'
      },
      chartCached: {
        name: 'chartCached',
        desc: 'Cinkciarz.pl: Keeps cached user`s settings of currency charts on the website.',
        duration: 'It does not expire'
      },
      converter: {
        name: 'converter',
        desc: 'Cinkciarz.pl: Keeps user\'s settings of the currency converter on Currency Exchange service page',
        duration: 'It does not expire'
      },
      converter_badge: {
        name: 'converter-badge(local storage)',
        desc: 'Cinkciarz.pl: Keeps user\'s settings of the currency converter on Currency Exchange service page',
        duration: 'It does not expire'
      },
      converter_seen: {
        name: 'converter-seen(local storage)',
        desc: 'Cinkciarz.pl: Keeps user\'s settings of the currency converter on Currency Exchange service page',
        duration: 'It does not expire'
      },
      currency: {
        name: 'currency',
        desc: 'Cinkciarz.pl: Stores your preferences for the currencies you choose.',
        duration: 'It does not expire'
      },
      dashboard_currency: {
        name: 'dashboard-currency(local storage)',
        desc: 'Cinkciarz.pl: Keeps dashboard selected currency',
        duration: 'It does not expire'
      },
      forex_invest_banner: {
        name: 'forex-invest-banner-*',
        desc: 'Cinkciarz.pl: Record of forex banner closure information.',
        duration: '10 years'
      },
      link_banner: {
        name: 'link_banner-*',
        desc: 'Cinkciarz.pl: Record of information about the closure of the payment banner with a link.',
        duration: '10 years'
      },
      remit_calculator: {
        name: 'remit_calculator_{language}',
        desc: 'Cinkciarz.pl: Keeps user`s settings of the currency converter on Money Transfers.',
        duration: 'It does not expire'
      },
      remits_payment_method: {
        name: 'remits-payment-method',
        desc: 'Cinkciarz.pl: Saves the last selected payment method on the US remittance calculator.',
        duration: '1 year'
      },
      social_orders_base_currency: {
        name: 'social-orders-base-currency-* (local storage)',
        desc: 'Cinkciarz.pl: Stores the input currency in a community order.',
        duration: 'It does not expire'
      },
      social_orders_quote_currency: {
        name: 'social-orders-quote-currency-* (local storage)',
        desc: 'Cinkciarz.pl: Stores the input currency in a community order.',
        duration: 'It does not expire'
      }
    },
    statsHeading: 'Statistics',
    statsLead:
        'These cookies allow us to analyse your behaviour on the website and, based on this, to improve its individual elements. ',
    stats: {
      _ga: {
        name: '_ga',
        desc: 'Google: Used to distinguish users. Registers a unique user number to collect statistical data about how you use our website.',
        duration: '24 months'
      },
      _ga_: {
        name: '_ga_*',
        desc: 'Google: Used to store your session status. Records how you use our website. Contains a container identifier in Google Analytics.',
        duration: '24 months'
      },
      _gat: {
        name: '_gat',
        desc: 'Google: Set by Google Analytics to control query volume.',
        duration: '1 minute'
      },
      _gid: {
        name: '_gid',
        desc: 'Google: Registers a unique user number to collect statistical data about how you use our website.',
        duration: '1 day'
      },
      collect: {
        name: 'collect',
        desc: 'Google: Used to transfer data to Google Analytics about the type of device and user behaviour. This allows us to recognise you, even if you use different devices.',
        duration: '1 session'
      },
      'r/collect': {
        name: 'r/collect',
        desc: 'Google: Used to send data to Google Analytics about your device type and behaviour. This allows us to recognise you even if you use different devices.',
        duration: '1 session'
      },
      __ls_exp: {
        name: '__ls_exp',
        desc: 'LiveSession: It enables gathering statistical data on how you use our website.',
        duration: '10 years'
      },
      __ls_off: {
        name: '__ls_off',
        desc: 'LiveSession: It enables gathering statistical data on how you use our website.',
        duration: '10 years'
      },
      __ls_sid: {
        name: '__ls_sid',
        desc: 'LiveSession: It enables gathering statistical data on how you use our website.',
        duration: '10 years'
      },
      __ls_uid: {
        name: '__ls_uid',
        desc: 'LiveSession: It enables gathering statistical data on how you use our website.',
        duration: '10 years'
      },
      __lsw_session: {
        name: '__lsw_session',
        desc: 'LiveSession: It enables gathering statistical data on how you use our website.',
        duration: '1 session'
      },
      _pk_id: {
        name: '_pk_id#',
        desc: 'Matomo: Collects statistics related to your visits on our website: number of visits, average time spent on the website, what pages have been read, etc.',
        duration: '13 months'
      },
      _pk_ses: {
        name: '_pk_ses#',
        desc: 'Matomo: Used by the Piwik Analytics Platform service to track user page queries during a session.',
        duration: '30 minutes'
      },
      'piwik.php': {
        name: 'piwik.php',
        desc: 'Matomo: Collects statistics related to your visits on our website: number of visits, average time spent on the website, what pages have been read, etc.',
        duration: '1 session'
      }
    },
    marketingHeading: 'Marketing',
    marketingLead:
        'With these cookies, we can perform more effective marketing activities, e.g. by displaying you ads that are more relevant to your interests.',
    marketing: {
      _gac_: {
        name: '_gac_*',
        desc: 'Google: Derived from Google Analytics and used by advertisers to measure user activity and the effectiveness of advertising campaigns.',
        duration: '90 days'
      },
      _gcl_au: {
        name: '_gcl_au',
        desc: 'Google: Acts as a "conversion linker". It collects information from ad clicks and stores it in its own cookie, so that conversions can be attributed to off-target pages.',
        duration: '90 days'
      },
      'ads/ga-audiences': {
        name: 'ads/ga-audiences',
        desc: 'Google: Google Pixel created for remarketing based on user behaviour on various websites.',
        duration: '1 session'
      },
      ANID: {
        name: 'ANID',
        desc: 'Google: Used to display Google ads on sites other than Google.',
        duration: '13 months/24 months (depend on a region)'
      },
      IDE: {
        name: 'IDE',
        desc: 'Google: Used by Google\'s DoubleClick service. Records your behaviour on our site when you click on an ad. Helps to measure the effectiveness of our ads.',
        duration: '13 months/24 months (depend on a region)'
      },
      NID: {
        name: 'NID',
        desc: 'Google: Records a unique number to recognise the device you are using. It is used for advertising.',
        duration: '6 months'
      },
      test_cookie: {
        name: 'test_cookie',
        desc: 'Google: Checks whether your browser supports cookies.',
        duration: '15 minutes'
      }
    },
    multimediaHeading: 'Multimedia',
    multimediaLead: 'These cookies allow you to watch YouTube videos directly on our website.',
    multimedia: {
      _Secure_ENID: {
        name: '_Secure-ENID',
        desc: 'YouTube (Google): Used to store user preferences and settings information.',
        duration: '13 months'
      },
      _Secure_YEC: {
        name: '_Secure-YEC',
        desc: 'YouTube (Google): Used to store user preferences, settings information and to detect and troubleshoot problems with the YouTube service. Also used for security, protection against spam, fraud and abuse.',
        duration: '13 months'
      },
      AEC: {
        name: 'AEC',
        desc: 'YouTube (Google): Used for security, protection against spam, fraud and abuse.',
        duration: '6 months'
      },
      DSID: {
        name: 'DSID',
        desc: 'YouTube (Google): Used to identify the logged-in user on non-Google sites so that their ad personalisation settings can be adhered to.',
        duration: '2 weeks'
      },
      HSID: {
        name: 'HSID',
        desc: 'YouTube (Google): Allows Google to block various attacks.',
        duration: '2 years'
      },
      PREF: {
        name: 'PREF',
        desc: 'YouTube (Google): Saves information such as preferred page configuration or playback settings.',
        duration: '8 months'
      },
      SID: {
        name: 'SID',
        desc: 'YouTube (Google): Allows Google to block various attacks.',
        duration: '2 years'
      },
      VISITOR_INFO1_LIVE: {
        name: 'VISITOR_INFO1_LIVE',
        desc: 'YouTube (Google): May allow you to display personalized recommendations on YouTube based on previous views and searches. It is also used to store user preferences, settings information, and to detect and troubleshoot issues with the YouTube service.',
        duration: '6 months'
      },
      YSC: {
        name: 'YSC',
        desc: 'YouTube (Google): Used to detect spam, fraud and abuse.',
        duration: '1 session'
      },
      other: {
        name: 'Other cookies and similar technologies used by YouTube (Google) - e.g.: yt-remote-device-id, yt.innertube::nextId, CONSENT',
        desc: 'YouTube (Google) may also use other cookies and similar technologies as described in its privacy policy -',
        duration: '-',
        url: 'https://policies.google.com/privacy',
        urlText: 'Privacy Policy – Privacy & Terms – Google'
      },
    }
  }
}
