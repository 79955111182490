import { render, staticRenderFns } from "./ModalCookies.vue?vue&type=template&id=2cdbf684&shadow"
import script from "./ModalCookies.vue?vue&type=script&lang=js&shadow"
export * from "./ModalCookies.vue?vue&type=script&lang=js&shadow"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  ,true
)

export default component.exports