<template>
  <button :class="className" @click="$emit('click')">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    outline: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: null
    },
    size: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className() {
      let options = [
        'variant',
        'outline',
        'size',
        'link',
        'inline'
      ]
      options = options
          .filter((option) => this[option])
          .map((option) =>
              'webc-' + (typeof this[option] === 'boolean' ? option : this[option])
          )
      if (!this.link) {
        options.unshift('webc-btn')
      }
      return options.join(' ')
    }
  }
}
</script>