<template>
  <div class="webc-modal-cookies-intro">
    <small>
      {{ translations.introLead }}
    </small>
    <VButton
        @click="$emit('change-view', 'settings')"
        link
        class="d-block"
        id="go_to_settings"
    >
      {{ translations.goToSettings }}
    </VButton>
    <div class="webc-row-buttons">
      <VButton
          @click="$emit('save-settings', 'required')"
          size="lg"
          outline
          id="accept_required_cookies"
      >
        {{ translations.acceptRequired }}
      </VButton>
      <VButton
          @click="$emit('save-settings', 'all')"
          size="lg"
          id="accept_all_cookies"
      >
        {{ translations.acceptAll }}
      </VButton>
    </div>
  </div>
</template>

<script>
import VButton from '../general/VButton';

export default {
  components: { VButton },
  inject: ['translations']
}
</script>
