<template>
  <transition
    name="collapse"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <div v-show="show">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.height = '0px'
    },
    enter(el) {
      this.setHeight(el)
    },
    afterEnter(el) {
      el.style.height = ''
    },
    beforeLeave(el) {
      this.setHeight(el)
    },
    leave(el) {
      this.setHeight(el)
      el.style.height = '0px'
    },
    afterLeave(el) {
      el.style.height = ''
    },
    setHeight(el) {
      el.style.height = `${el.scrollHeight}px`
    },
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    toggle() {
      this.show = !this.show
    }
  }
}
</script>
